import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { wheelSections } from '../content/wheel';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  width: 45vw;
  @media screen and (max-width: 768px) {
    width: 100vw;
    padding: 40px;
  }
`;

const Title = styled.h3`
  font-size: 38px;
  font-weight: 300;
`;

const AreaOfStudy = styled.h4`
  font-weight: 600;
  margin-top: 45px;
  font-family: ${(props) => props.theme.fonts.excerpt};
`;

const Text = styled.p`
  margin-top: 45px;
  max-width: 550px;
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-weight: 300;
`;

const Button = styled.button`
  display: inline-block;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.third};
  font-family: ${(props) => props.theme.fonts.excerpt};
  font-weight: 900;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  width: fit-content;
  margin-top: 45px;
  &:hover {
    cursor: pointer;
  }
`;

export const options = ['Relationships', 'Self-Knowledge', 'Professional Life', 'Play', 'Spirituality', 'Health'];

export default ({ choice }) => {
  
  if (choice > 5) {
    choice = 0;
  }
  return (
    <Wrapper>
      <Container>
        <Title>What is Your Purpose?</Title>
        <AreaOfStudy>{options[choice]}</AreaOfStudy>
        <Text>
          {wheelSections[choice]}
        </Text>
        <Button onClick={() => navigate(`/${options[choice].toLowerCase().replace(' ', '-')}`)}>Study {options[choice]}</Button>
      </Container>
    </Wrapper>
  )
};
