import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #f6f6f9;
  width: 100%;
`;

const Container = styled.div`
  background: #f6f6f9;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: ${props => props.isExplore ? '30px 0' : '90px 15px'};
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CardList = ({ children, isExplore = false }) => (
  <Wrapper >
    <Container isExplore={isExplore}>{children}</Container>
  </Wrapper>
);

export default CardList;
