import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Container = styled.div`
  justify-content: center;
  margin: 0 auto 86px auto;
  width: 100%;
  max-width: 420px;
  a {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    margin: 0 auto 86px auto;
    max-width: 90vw;
  }
`;

const Image = styled.img`
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
  transition: all 0.2s;
  width: 100%;
  height: 234px;
  &:hover {
    background-image: linear-gradient(120deg, #a0baf9 0%, #f07a80 100%);
  }
  @media screen and (max-width: 768px) {
    height: 49vw;
  }
`;

const Title = styled.h4`
  color: ${props => props.theme.colors.slate};
  font-family: ${props => props.theme.fonts.title};
  font-size: 38px;
  margin-top: 24px;
`;

const Excerpt = styled.div`
  color: ${props => props.theme.colors.lightGray};
  font-family: ${props => props.theme.fonts.excerpt};
  font-size: 18px;
  margin-top: 24px;
  font-weight: 300;
`;

const ReadMore = styled.p`
  color: ${props => props.theme.colors.important};
  font-family: ${props => props.theme.fonts.excerpt};
  font-size: 18px;
`;

const Card = ({ index, node = {} }) => {
  const { excerpt = 'Lorem ipsum', slug = 'library', title = 'Title', jetpack_featured_media_url = null, img } = node;

  const image = jetpack_featured_media_url
    ? jetpack_featured_media_url
    : img.src;

  return (
    <Container>
      <Link to={`/${slug}`}><Image src={image}/></Link>
      <Title><Link to={`/${slug}`}>{title.replace('&nbsp;', ' ').replace(/&#8217;/g, '\'')}</Link></Title>
      <Excerpt dangerouslySetInnerHTML={{__html: excerpt.replace(/<a class="more-link"[\s\S]+<\/a>/, '...')}}/>
      <ReadMore><Link to={`/${slug}`}>Read More...</Link></ReadMore>
    </Container>
  )
};

export default Card;
