import React from 'react';
import styled from 'styled-components';

import Button from '../components/Button';
import Explore from '../components/Explore';
import HeaderMargin from '../components/HeaderMargin';
import Layout from '../components/layout';
import LearnMoreSmall from '../components/LearnMoreSmall';
import SEO from '../components/seo';
import { options } from '../components/WheelChoice';

const Container = styled.div`
  margin: 60px auto;
  width: 90vw;
  max-width: 1080px;
`;

const Title = styled.h2`
  margin-top: 16px;
  font-size: 36px;
  font-family: ${(props) => props.theme.fonts.title};
`;

const Content = styled.div`
  width: 100%;
  p {
    margin: 20px 0;
    font-family: ${(props) => props.theme.fonts.excerpt};
    font-weight: 300;
  }
  iframe {
    width: 90vw;
  }
`;

const Image = styled.img`
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
  width: 100%;
`;

const Article = ({ pageContext: { node, isStudy} }) => {
  const {
    content, title, jetpack_featured_media_url: jetpackFeaturedMediaUrl, jetpack_related_posts: jetpackRelatedPosts
  } = node;
  const relatedPosts = jetpackRelatedPosts
    .filter((post) => !options.includes(post.title))
    .slice(0, 3)
    .map((post) => {
      const newPost = post;
      newPost.slug = newPost.url.split('/')[6];
      return newPost;
    });

  return (
    <Layout>
      <SEO title={title} />
      <HeaderMargin />
      <Container>
        <Image src={jetpackFeaturedMediaUrl} />
        <Title>{title.replace('&nbsp;', ' ').replace(/&#8217;/g, '\'')}</Title>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
        { isStudy && <Button text="Spin The Wheel Again"/> }
      </Container>
      <Explore posts={relatedPosts} />
      <LearnMoreSmall />
    </Layout>
  );
};


export default Article;
