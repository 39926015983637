import React from 'react';
import styled from 'styled-components';

import Card from './Card';
import CardList from './CardList';

const Wrapper = styled.div`
  background: #f6f6f9;
  padding: 5vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  background: #f6f6f9;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1280px;
  width: 90vw;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Title = styled.h4`
  color: ${props => props.theme.colors.slate};
  font-size: 38px;
  font-family: ${props => props.theme.fonts.title};
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
`;

const Explore = ({ children, posts }) => (
  <Wrapper>
    <Title>More to Explore...</Title>
    <CardList isExplore={true}>
      { posts.map((node) => (
        <Card node={node} />
      )) }
    </CardList>
  </Wrapper>
);

export default Explore;
